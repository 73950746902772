import { Component, Optional, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
	Event,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router
} from '@angular/router';

@Component({
	selector: 'chankya-app',
    templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None
})

export class ChankyaAppComponent {

	loading = true;

   	constructor(translate: TranslateService, private router: Router) {
		translate.addLangs(['es', 'fr', 'he', 'ru' , 'ar' , 'zh' ,'de' , 'en', 'ja', 'ko' , 'it' ,'hu']);
		translate.setDefaultLang('es');

		const browserLang: string = translate.getBrowserLang();
		translate.use(browserLang.match(/es|fr/) ? browserLang : 'es');

		// Cargando Progress Bar
		this.router.events.subscribe((event: Event) => {
			switch (true) {
			  case event instanceof NavigationStart: {
				this.loading = true;
				break;
			  }
			  case event instanceof NavigationEnd:
			  case event instanceof NavigationCancel:
			  case event instanceof NavigationError: {
				this.loading = false;
				break;
			  }
			  default: {
				break;
			  }
			}
		});
	}
}
