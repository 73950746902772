import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from "rxjs";
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
    // Desarrollo : 'https://desarrolloback.multientregapanama.com/api/' | Produccion : https://api.multientregapanama.com/api/
  dominio = environment.dominio
  dominioCORS = '/api/';
  dominio_img = 'https://api.multientregapanama.com/api/image/view?url='/* 'https://api.multientregapanama.com/api/image/view?url=' */;
  //dominio = 'http://ec2-18-222-184-49.us-east-2.compute.amazonaws.com/multientrega-serve/public/index.php/api/';

  constructor(
    private _http: Http,
    private httpc : HttpClient,
    private router: Router
  ) { }

  public postMethodReal(formdata: any, endpoint: any, domain = this.dominio){
    let urlOperation = domain + endpoint;
    let headers = new Headers();
    headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));

    console.log(urlOperation);
    return this._http.post(urlOperation, formdata, { headers: headers }).map(res => {
        return res.json();
      },err=>{
        return err;
      }
    );
  }

  public postMethod(params: any, endpoint: any, domain = this.dominio, spinnerSta = true) {
      // Variables para el servicio
      let urlOperation = domain + endpoint;
      let paramsUrl = new URLSearchParams();
      let headers = new Headers();
      headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));
      for (let i = 0; i < params.length; i++) {
          paramsUrl.append(params[i].key, params[i].value);
      }
      // Asignamos las opciones para el Request
      let options = new RequestOptions();
      options.body = paramsUrl;
      options.headers = headers;
      let body = paramsUrl.toString();

      // Hacemos el llamado a la APIRest
      console.log(urlOperation);

      return this._http.post(urlOperation, body, { headers: headers }).map(res => {
        return res.json();
      },err=>{
        console.log(JSON.parse(err._body));
        return err;
      }
      );
  }
  public postMethodform(formdata: any, endpoint: any, html = false) {
      let urlOperation = this.dominio + endpoint;
      let headers = new Headers();
      headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));

      console.log(urlOperation);
      return this._http.post(urlOperation, formdata, { headers: headers }).map(res => {
        if (html) return res;
        return res.json();
      },err=>{
        return err;
      }
      );
  }

  public getMethod(params: any, endpoint: any, cors = false, domain = this.dominio) {

      // Variables para el servicio
      let urlOperation = domain + endpoint;
      if (cors) urlOperation = this.dominioCORS + endpoint;
      let paramsUrl = new URLSearchParams();

      // Definimos los Header de la peticion
      let headers = new Headers();
      if (cors) headers.append('Content-Type', 'application/ms-excel'); else headers.append('Content-Type', 'application/x-www-form-urlencoded');
      headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));
      // Recorremos los parametros para convertirlos a parametros de url
      for (let i = 0; i < params.length; i++) {
        paramsUrl.append(params[i].key, params[i].value);
      }

      // Asignamos las opciones para el Request
      let options = new RequestOptions();
      options.search = paramsUrl;
      options.headers = headers;

      // Hacemos el llamado a la APIRest
      return this._http.get(urlOperation, options)
        .map(res => {
          if (cors) return res;
          return res.json();
        },err=>{
          return err;
        }
      );
  }

  public getMethodExcel(params: any, endpoint: any, domain = this.dominio) {

    // Variables para el servicio
    let urlOperation = domain + endpoint;
    let paramsUrl = new URLSearchParams();

    // Definimos los Header de la peticion
    let headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));
    // Recorremos los parametros para convertirlos a parametros de url
    for (let i = 0; i < params.length; i++) {
      paramsUrl.append(params[i].key, params[i].value);
    }

    // Asignamos las opciones para el Request
    let options = new RequestOptions();
    options.search = paramsUrl;
    options.headers = headers;

    // Hacemos el llamado a la APIRest
    return this._http.get(urlOperation, options)
      .map(res => {
        return res.json();
      },err=>{
        return err;
      }
    );
  }

  public putMethod(formdata: any, endpoint: any, id: any = "", domain = this.dominio) {

    let urlOperation = domain + endpoint + "/" + id;
    let headers = new Headers();
    headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));

    console.log(urlOperation);
    return this._http.put(urlOperation, formdata, { headers: headers }).map(res => {
        return res.json();
      },err=>{
        return err;
      }
    );
  }

  public deleteMethod(endpoint: any, id: any, domain = this.dominio) {

    let urlOperation = domain + endpoint + "/" + id;
    let headers = new Headers();
    headers.append('Access-Token', 'Bearer ' + localStorage.getItem("access_token"));

    console.log(urlOperation);
    return this._http.delete(urlOperation, { headers: headers }).map(res => {
        return res.json();
      },err=>{
        return err;
      }
    );
  }

  public logoutMethod(token: string){
    let urlOperation = this.dominio + "logout";
    return this._http.post(urlOperation, {'token': token}).map(res => {
      return res.json();
    },err=>{
      return err;
    }
    );
  }

}
